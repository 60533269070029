/** Home Page Styles*/

/***Common**/
.btn-rounded{
  border-radius: 10px !important;
}
.intrithm-btn{
  padding: 0.375rem 1.75rem !important;
  box-shadow: inset -4px 4px 4px rgb(252 252 252 / 25%), inset 4px -4px 4px rgb(0 0 0 / 25%) !important;
}
.card{
 background: #111111 !important;
 box-shadow: inset -7px -7px 14px rgba(61, 58, 58, 0.25), inset 7px 7px 14px rgba(156, 149, 149, 0.24)!important;
 border-radius: 50px!important;
}
.card-deep {
background: rgba(0, 0, 0, 0.05) !important;
box-shadow: inset 0px 40px 40px rgba(0, 0, 0, 0.2), inset 0px 10px 40px rgba(255, 255, 255, 0.5)!important;
backdrop-filter: blur(40px)!important;
border-radius: 52px!important;
}
.card-rounded {
  border-radius: 50% !important;
  width: 120px !important;
  height: 120px!important;
  box-shadow: inset -4px 4px 4px rgba(117, 115, 115, 0.25), inset 4px -4px 4px rgba(111, 100, 100, 0.25) !important;

}
.text-yellow {
  color: #FBCA05 !important;
}
input {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125) !important;
  background: #111111 !important;
  color: white !important;
  padding: 1.375rem 1.75rem !important;
 box-shadow: inset -7px -7px 14px rgba(61, 58, 58, 0.25), inset 7px 7px 14px rgba(156, 149, 149, 0.24)!important;
 border-radius: 20px!important;
}
textarea{
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125) !important;
  background: #111111 !important;
  color: white !important;
  resize: none !important;
  padding: 1.375rem 1.75rem !important;
 box-shadow: inset -7px -7px 14px rgba(61, 58, 58, 0.25), inset 7px 7px 14px rgba(156, 149, 149, 0.24)!important;
 border-radius: 20px!important;
}
ul{
  color: #FBCA05 !important;
}
/* .card:hover{
  background: linear-gradient(145deg, #202020, #272727) !important;
  box-shadow:  22px 22px 31px #1f1f1f,
               -22px -22px 31px #292929 !important;
 } */
/** NavBar Styles**/
.navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .show>.nav-link {
  color: #FBCA05 !important;
}
.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: #FBCA05 !important;
}

/**Home Banner**/
.circule{
  width: 80px;
  height: 80px;
  border-radius: 40px;
}
.circule.violet{
  top: -8%;
  left: -2%;
  background: #7000FF;
  position: absolute;
  box-shadow: inset -4px 4px 4px #fbca05;
}
.circule.red{
  right: -3%;
  bottom: -6%;
  position: absolute;
  background: #FF003D;
  box-shadow: inset -4px 4px 4px #FBCA05, inset 4px -4px 4px rgba(0, 0, 0, 0.25);
}
.circule.black{
  position: relative;
  left: 40%;
  top: -107px;
  background: radial-gradient(50% 50% at 50% 50%, #000000 50%, rgb(72 72 72 / 80%) 100%);
}
.bg-glass{
  background: rgba(255, 255, 255, 0.2);
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
border-radius: 35px;
}
.glass{
height: 258.53px;
background: rgba(255, 255, 255, 0.2);
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
border-radius: 35px;
}
.bulb {
  position: absolute;
  top: 195px;
  left: 108.23px;
transform: rotate(-30.72deg);

}
.hometitle {
position: absolute;
width: 459px;
font-style: normal;
font-weight: normal;
font-size: 50px;
line-height: 56px;
bottom: -23%;
left: 3%;
color: #FBCA05;
}
.yellow-card{
  width: 352px;
  height: 499px;
  background: linear-gradient(13.98deg, #FBCA05 4.1%, rgba(251, 202, 5, 0) 98.19%);
  box-shadow: inset 4px -4px 4px rgba(0, 0, 0, 0.25);
  filter: drop-shadow(-4px 4px 4px rgba(7, 7, 7, 0.25));
  border-radius: 0px 70px;
}
.home-product-card{
position: absolute;
width: 219px;
height: 160px;
background: #000000;
box-shadow: inset -7px -7px 14px rgba(61, 58, 58, 0.25), inset 7px 7px 14px rgba(156, 149, 149, 0.24);
border-radius: 31px;
transform: rotate(-0.52deg);
}
.home-product-card.one{
  left: -28%;
  top: 6%;
}
.start-25{
  left: 25% !important;
}
.bottom-negative-25{
  bottom: -25% !important;
}
.home-product-card.two{
  left: -28%;
  bottom: 18%;
}
.home-after-glass{
  position: absolute;
  bottom: 13%;
  left: 6%;
}
.home-product-card.three{
  left: 1%;
  top: 26%;
}
.home-product-card.four{
  right: 0;
  bottom: 18%;
}
.product-image {
  min-width: 100% !important;
  height: 100% !important;
  border-radius: 10%;
}
.view-products{
  position: absolute;
left: 939px;
top: 580px;
}
.work-title{
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 36px;
  color: #FBCA05;
}
.work-content{
  font-family: Poppins;
font-style: normal;
font-weight: 300;
font-size: 12px;
line-height: 21px;
text-align: left;
}
.circule-img {
  width: 80px!important;
  height: 80px!important;
  align-self: center;
}

@media only screen and (max-width: 600px) {
  .hometitle {
    font-size: 29px;
    line-height: 46px;
    width: 280px;
  }
  .yellow-card{
    width: 252px;
    height: 399px;
    background: linear-gradient(13.98deg, #FBCA05 4.1%, rgba(251, 202, 5, 0) 98.19%);
  box-shadow: inset 4px -4px 4px rgba(0, 0, 0, 0.25);
  filter: drop-shadow(-4px 4px 4px rgba(7, 7, 7, 0.25));
  border-radius: 0px 50px;
  }
.home-product-card{
    width: 160px;
    height: 130px;
  }
}